<template>
    <v-container fluid class="container--fluid fill-height mx-0 px-0 py-0 my-0">
        <v-row align="center" justify="center" class="text-center">
            <v-col cols="12">
                <v-img src="@/assets/images/header-bg.jpg"
                       class="align-end"
                       transition="slide-x-transition"
                       width="100%" height="300">
                </v-img>
            </v-col>
            <v-col cols="12">
                <span class="mt-10 text-h3 font-weight-light text-uppercase">Sección sin Contenido</span>
            </v-col>
            <v-col cols="12">
                <v-btn
                    class="mb-10"
                    color="primary"
                    outlined
                    large
                    rounded
                    to="/homepage">
                    HomePage
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "EmptyPage",
        title: "Página sin Contenido | Private",
    }
</script>

<style scoped>

</style>